import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, logout } from "../../redux/authSlice";
// Chakra Imports
import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom Icons
import { ProfileIcon, LogoutIcon } from "components/Icons/Icons";
// Custom Components
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import routes from "routes.js";

import { logoutService } from "../../api/logoutService";

export default function HeaderLinks(props) {
  const dispatch = useDispatch();
  const { authToken } = useSelector(selectAuth);
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const dashboardRoutes = routes.filter((route) => route?.noRender !== true);

  // Chakra Color Mode
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const handleLogout = () => {
    logoutService(authToken);
    dispatch(logout());
    window.location.href = "/";
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <NavLink to="/admin/dashboard">
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            ) : (
              ""
            )
          }
        >
          <Text display={{ sm: "none", md: "flex" }}>{`Welcome ${props?.adminInfo?.name}`}</Text>
        </Button>
      </NavLink>

      <Button
        ms="0px"
        px="0px"
        me={{ sm: "2px", md: "16px" }}
        color={navbarIcon}
        variant="transparent-with-icon"
        rightIcon={
          document.documentElement.dir ? (
            ""
          ) : (
            <LogoutIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          )
        }
        leftIcon={
          document.documentElement.dir ? (
            <LogoutIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          ) : (
            ""
          )
        }
        onClick={handleLogout}
      >
        <Text display={{ sm: "none", md: "flex" }}>Logout</Text>
      </Button>

      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={dashboardRoutes}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
