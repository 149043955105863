import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import {
  Flex,
  Input,
  Select,
  Stack,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getPricingType } from "../../Utils";

function PromoCode() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntities] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["ID", "Pricing Type", "Coupon Code", "Valid Upto", "Action"];
  const pageName = "Promo Code";
  const defaultFormValue = {
    pricing_type: 0,
    coupon_code: "",
    valid_upto: "",
  };
  const [formData, setFormData] = useState({ ...defaultFormValue });

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [pricingTypeError, setPricingTypeError] = useState("");
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);

  const customStyles = {
    input: {
      width: "100%",
      padding: "0.5rem",
      fontSize: "1rem",
      borderRadius: "0.375rem",
      borderWidth: "1px",
      borderColor: "gray.300",
      _focus: {
        outline: "none",
        borderColor: "blue.500",
        boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.6)",
      },
    },
  };

  const handleChange = (key, value) => {
    if (key === "valid_upto") {
      const dateOnly = moment(value).startOf("day");
      setFormData({ ...formData, [key]: dateOnly.format("YYYY-MM-DD HH:mm:ss") });
    } else {
      setFormData({ ...formData, [key]: value });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!formData.coupon_code) {
        setPricingTypeError("Coupon code required");
        return;
      }
      if (!formData.valid_upto) {
        setPricingTypeError("Valid upto required");
        return;
      }

      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}updatecoupons/${editForm?.id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}createcoupons`, formData, requestHeader);
      }

      setEntityCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setPricingTypeError(errors.subject_name || "");
        }
      } else if (response?.data?.status === true) {
        setEntityCreatedMessage(response?.data?.message);
        setFormData({ ...defaultFormValue });
        setShowAddForm(false);
        setEditForm(null);
        fetchData();
      }
    } catch (error) {
      setPricingTypeError("");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getcouponslist`, requestHeader);
      setEntities(response?.data?.couponslist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleAddNewEntity = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setFormData({ ...defaultFormValue });
    scrollToTop();
  };

  const handleEditForm = (entity) => {
    setShowAddForm(true);
    setEditForm(entity);
    setFormData({
      pricing_type: entity.pricing_type,
      coupon_code: entity.coupon_code,
      valid_upto: entity.valid_upto,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewEntity}
        >
          Add New {pageName}
        </Button>
      </Stack>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? `Edit` : `Add`} {pageName}
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl>
                  <FormLabel>Pricing Type</FormLabel>
                  <Select onChange={(e) => handleChange("pricing_type", e.target.value)}>
                    <option value="0" selected={formData?.pricing_type === 0 ? "selected" : ""}>
                      Student
                    </option>
                    <option value="1" selected={formData?.pricing_type === 1 ? "selected" : ""}>
                      School
                    </option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Coupon Code</FormLabel>
                  <Input
                    placeholder="Coupon Code"
                    value={formData?.coupon_code}
                    onChange={(e) => handleChange("coupon_code", e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Valid Upto</FormLabel>
                  <Datetime
                    inputProps={{ style: customStyles.input }}
                    placeholder="Valid Upto"
                    value={formData?.valid_upto}
                    onChange={(e) => handleChange("valid_upto", e)}
                  />
                </FormControl>

                <FormControl>
                  <Button mt="30px" colorScheme="purple" isLoading={loading} onClick={handleSubmit}>
                    {editForm ? "Update" : "Create"} {pageName}
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
          <Text color="red" fontWeight="bold">
            {pricingTypeError}
          </Text>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.id}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{getPricingType(row?.pricing_type)}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{row?.coupon_code}</Text>
                    </Td>
                    <Td>
                      <Text color={textColor}>{row?.valid_upto}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PromoCode;
