import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/authSlice";
import { useHistory, Redirect } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  Center,
  Image,
  Link,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/kids-exam-bg.jpg";
import mttLogo from "assets/img/my-transfer-teacher.png";
import { PROJECT_TITLE, API_ENDPOINT } from "../../constants";
import { isEmailValid, isPasswordValid } from "../../Utils";

function SignIn() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  // Redirect to the dashboard if already authenticated
  if (isAuthenticated) {
    return <Redirect to="/admin/dashboard" />;
  }

  // const isEmailValid = (email) => {
  //   // Simple email validation regex
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // const isPasswordValid = (password) => {
  //   // Password should be at least 6 characters
  //   // It should contain at least one alphabet, one numeric digit, and one special character
  //   const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  //   return passwordRegex.test(password);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    if (!email || !password) {
      // Handle validation error, e.g., show an error message
      setError("Please enter both email and password.");
      return;
    }

    // Validate the email
    if (!isEmailValid(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Validate the password
    if (!isPasswordValid(password)) {
      // Handle password validation error, e.g., show an error message
      setError(
        "Password must be at least 6 characters and include alphabet, numeric, and special characters.",
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      const response = await axios.post(`${API_ENDPOINT}login`, formData);
      const authToken = response?.data?.token;
      if (authToken) {
        dispatch(login({ authToken }));
        history.push("/admin/dashboard");
      } else {
        setError(`Login failed ${response?.data?.message}`);
      }
    } catch (error) {
      setError("Error during login:", error);
    }
  };

  return (
    <Flex mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "20px", md: "20px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex direction="column" w="100%" background="transparent" p="12px">
            <Heading color={titleColor} fontSize="24px" mb="8px" textAlign="center">
              <Center>
                <Image
                  align="center"
                  src={mttLogo}
                  alt={PROJECT_TITLE}
                  mb="8px"
                  width={{ md: "150px", lg: "150px" }}
                />
              </Center>
              {PROJECT_TITLE}
            </Heading>
            <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              Enter your email and password to sign in
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="12px"
                  fontSize="sm"
                  type="text"
                  placeholder="Your email adress"
                  id="login-email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="12px"
                  fontSize="sm"
                  type="password"
                  id="login-password"
                  placeholder="Your password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  fontSize="10px"
                  type="submit"
                  bg="teal.300"
                  w="100%"
                  h="45"
                  mb="12px"
                  color="white"
                  mt="12px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  SIGN IN
                </Button>
              </FormControl>
            </form>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Text
              color="gray.400"
              textAlign={{
                base: "center",
                xl: "start",
              }}
              mb={{ base: "12px", xl: "0px" }}
            >
              &copy; {1900 + new Date().getYear()}, <Text as="span">Made with ❤️ by</Text>
              <Link
                // color={linkTeal}
                color="teal.400"
                href="https://spinfotec.com/"
                target="_blank"
              >
                Spinfotec
              </Link>
            </Text>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
