/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px="30px"
      pb="20px"
    >
      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "12px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()}, <Text as="span">Made with ❤️ by</Text>
        <Link
          // color={linkTeal}
          color="teal.400"
          href="https://spinfotec.com/"
          target="_blank"
        >
          Spinfotec
        </Link>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: "12px",
            md: "44px",
          }}
        >
          <Link color="gray.400" href="mailto:admin@spinfotec.com">
            admin@spinfotec.com
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "12px",
            md: "12px",
          }}
        >
          <Link color="gray.400" href="tel:00447436787758">
            00447436787758
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "12px",
            md: "44px",
          }}
        >
          <Link color="gray.400" href="#">
            51 A Carnreagh Rd Castlewellan Northern Ireland United Kingdom BT31 9NY
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
