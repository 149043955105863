import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getStatus } from "../../Utils";

function Subject() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntities] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["Subject ID", "Subject Name", "Status", "Action"];
  const pageName = "Subject";
  const [formData, setFormData] = useState({
    subject_name: "",
    status: "1",
  });

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [nameError, setNameError] = useState("");
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (key === "name") setNameError("");
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setNameError("");

      if (!formData.subject_name) {
        setNameError("Subject Name required");
        return;
      }

      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}updatesubject/${editForm?.subject_id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}createsubject`, formData, requestHeader);
      }

      setEntityCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setNameError(errors.subject_name || "");
        }
      } else if (response?.data?.status === true) {
        setEntityCreatedMessage(response?.data?.message);
        setFormData({
          subject_name: "",
          status: "1",
        });
        setShowAddForm(false);
        setEditForm(null);
        fetchData();
      }
    } catch (error) {
      setNameError("");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getsubjectlist`, requestHeader);
      setEntities(response?.data?.subjectlist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleAddNewEntity = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setFormData({
      subject_name: "",
      status: "1",
    });
    scrollToTop();
  };

  const handleEditForm = (entity) => {
    setShowAddForm(true);
    setEditForm(entity);
    setFormData({
      subject_name: entity.subject_name,
      status: entity.status,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewEntity}
        >
          Add New {pageName}
        </Button>
      </Stack>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? `Edit : ${editForm.subject_name}` : `Add ${pageName}`}
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl isInvalid={!!nameError}>
                  <FormLabel>{pageName} Name</FormLabel>
                  <Input
                    type="text"
                    name="subject_name"
                    value={formData.subject_name}
                    onChange={(e) => handleChange("subject_name", e.target.value)}
                    placeholder="Enter name"
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    value={formData.status.toString()}
                    onChange={(value) => handleChange("status", value)}
                  >
                    <Stack direction="row">
                      <Radio value="1">Enabled</Radio>
                      <Radio value="0">Disabled</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <Button mt="20px" colorScheme="purple" isLoading={loading} onClick={handleSubmit}>
                    {editForm ? "Update" : "Create"} {pageName}
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.subject_id}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.subject_name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Subject;
