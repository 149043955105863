import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

const TruncatedParagraph = ({ html }) => {
  const [showFull, setShowFull] = useState(false);

  const toggleShowFull = () => {
    setShowFull(!showFull);
  };

  const buttonText = showFull ? "Show less" : "Show more";

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: showFull ? html : `${html.slice(0, 200)}...` }} />
      <Button size="xs" type="button" colorScheme="blue" onClick={toggleShowFull}>
        {buttonText}
      </Button>
    </div>
  );
};

export default TruncatedParagraph;
