import { Badge } from "@chakra-ui/react";

export const isEmailValid = (email) => {
  // Simple email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isPasswordValid = (password) => {
  // Password should be at least 6 characters
  // It should contain at least one alphabet, one numeric digit, and one special character
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return passwordRegex.test(password);
};

export const getStatus = (status) => {
  let adminStatus = "";
  switch (status) {
    case 0:
      adminStatus = (
        <Badge variant="solid" colorScheme="red">
          Disabled
        </Badge>
      );
      break;
    case 1:
      adminStatus = (
        <Badge variant="solid" colorScheme="green">
          Enabled
        </Badge>
      );
      break;
  }
  return adminStatus;
};

export const getQuestionLevel = (status) => {
  let adminStatus = "";
  switch (status) {
    case 1:
      adminStatus = (
        <Badge variant="solid" colorScheme="blue">
          Easy
        </Badge>
      );
      break;
    case 2:
      adminStatus = (
        <Badge variant="solid" colorScheme="blue">
          Medium
        </Badge>
      );
      break;
    case 3:
      adminStatus = (
        <Badge variant="solid" colorScheme="blue">
          Difficult
        </Badge>
      );
      break;
  }
  return adminStatus;
};

export const getQuestionType = (status) => {
  let adminStatus = "";
  switch (status) {
    case 0:
      adminStatus = (
        <Badge variant="solid" colorScheme="cyan">
          Normal
        </Badge>
      );
      break;
    case 1:
      adminStatus = (
        <Badge variant="solid" colorScheme="cyan">
          Graphical
        </Badge>
      );
      break;
  }
  return adminStatus;
};

export const getExamType = (status) => {
  let adminStatus = "";
  switch (status) {
    case 1:
      adminStatus = (
        <Badge variant="solid" colorScheme="teal">
          Mock
        </Badge>
      );
      break;
    case 2:
      adminStatus = (
        <Badge variant="solid" colorScheme="orange">
          Practice
        </Badge>
      );
      break;
  }
  return adminStatus;
};

export const getDivisionNames = (divisions, divisionList) => {
  const divisionsData = JSON.parse(divisions);
  return (
    divisionsData
      .map((divisionId) => {
        const division = divisionList.find((item) => item.division_id === parseInt(divisionId));
        return division ? division.division_name : null;
      })
      .filter(Boolean)
      .join(", ") || "All Divisions"
  );
};

export const getPricingType = (status) => {
  let adminStatus = "";
  switch (status) {
    case 0:
      adminStatus = (
        <Badge variant="solid" colorScheme="teal">
          Student
        </Badge>
      );
      break;
    case 1:
      adminStatus = (
        <Badge variant="solid" colorScheme="orange">
          School
        </Badge>
      );
      break;
  }
  return adminStatus;
};
