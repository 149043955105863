import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";

function Uac() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const { id: admin_id } = useParams();

  const [pages, setPages] = useState([]);
  const [pageCreated, setPageCreated] = useState(false);
  const [pageCreatedMessage, setPageCreatedMessage] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["SiNo", "Page Name", "Action"];

  const handleUacForm = async () => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}updateadminuaclist/${admin_id}`,
        { page_ids: checkedIds },
        requestHeader,
      );
      setPageCreated(response?.data?.status);
      setPageCreatedMessage(response?.data?.message);
      fetchData();
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const handleCheckboxChange = (value) => {
    let status;
    if (checkedIds.includes(value)) {
      setCheckedIds((prevIds) => prevIds.filter((id) => id !== value));
      status = false;
    } else {
      setCheckedIds((prevIds) => [...prevIds, value]);
      status = true;
    }
    let pagelist = pages.map((page) => (page.id === 3 ? { ...page, checked: status } : page));
    setPages(pagelist);
  };

  const fetchData = async () => {
    try {
      const uacResponse = await axios.get(
        `${API_ENDPOINT}getadminuaclist/${admin_id}`,
        requestHeader,
      );
      const page_ids = uacResponse?.data?.page_ids ?? [];
      setCheckedIds(page_ids);

      const response = await axios.get(`${API_ENDPOINT}getpagelist`, requestHeader);
      let pagelist = response?.data?.pagelist.map((page) => ({
        ...page,
        checked: page_ids.includes(page.page_id),
      }));
      setPages(pagelist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setPageCreatedMessage("");
    setPageCreated("");
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {pageCreatedMessage && (
        <Alert status={pageCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{pageCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              User Access Control
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {pages.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{index + 1}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <input
                          type="checkbox"
                          name="page_id[]"
                          value={row.page_id}
                          onChange={() => handleCheckboxChange(row.page_id)}
                          checked={checkedIds.includes(row.page_id)}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}

              <Tr>
                <Td colSpan={3} align="right">
                  <Button
                    style={{ float: "right" }}
                    colorScheme="orange"
                    mr="8px"
                    mb="8px"
                    onClick={() => handleUacForm()}
                  >
                    Update UAC
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Uac;
