import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Stack,
  Box,
  Grid,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getExamType, getStatus, getDivisionNames } from "../../Utils";

function Subject() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntities] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = [
    "SiNo",
    "Exam Type",
    "Question Count",
    "Subject",
    "Division",
    "Time Limit",
    "Status",
    "Action",
  ];
  const pageName = "Exam";
  const [formData, setFormData] = useState({
    id: 0,
    type: 1,
    question_count: 1,
    subject_id: 0,
    division_id: [],
    time_limit: 10,
    status: 1,
  });

  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [formError, setFormError] = useState("");
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setFormError("");
      if (formData?.question_count <= 0) {
        setFormError("Question count required");
        return;
      }
      if (formData?.time_limit <= 0) {
        setFormError("Question count required");
        return;
      }

      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}update_examconfig/${editForm?.id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}create_examconfig`, formData, requestHeader);
      }
      setEntityCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setFormError(errors.subject_name || "");
        }
      } else if (response?.data?.status === true) {
        setEntityCreatedMessage(response?.data?.message);
        setFormData({
          id: 0,
          type: 1,
          question_count: 1,
          subject_id: 0,
          division_id: [],
          time_limit: 10,
          status: 1,
        });
        setShowAddForm(false);
        setEditForm(null);
        fetchData();
      }
    } catch (error) {
      setFormError("");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getexamconfiglist`, requestHeader);
      setEntities(response?.data?.examconfiglist ?? []);
    } catch (err) {
      console.error(err);
    }

    try {
      const subjectsResponse = await axios.get(`${API_ENDPOINT}getsubjectlist`, requestHeader);
      setSubjectList(subjectsResponse?.data?.subjectlist ?? []);
    } catch (err) {
      console.error(err);
    }

    try {
      const subjectsResponse = await axios.get(`${API_ENDPOINT}getdivisionlist`, requestHeader);
      setDivisionList(subjectsResponse?.data?.divisionlist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDivisionChange = (selected) => {
    handleChange("division_id", selected);
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleAddNewEntity = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setFormData({
      id: 0,
      type: 1,
      question_count: 1,
      subject_id: 0,
      division_id: [],
      time_limit: 10,
      status: 1,
    });
    scrollToTop();
  };

  const handleEditForm = (entity) => {
    setShowAddForm(true);
    setEditForm(entity);
    setFormData({
      id: entity?.id,
      type: entity?.type,
      question_count: entity?.question_count,
      subject_id: entity?.subject_id,
      division_id: JSON.parse(entity?.division_id),
      time_limit: entity?.time_limit,
      status: entity?.status,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  console.log("MYLOG formData", formData);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewEntity}
        >
          Add New {pageName}
        </Button>
      </Stack>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? "Edit " : "Add "} {pageName}
            </Text>
          </CardHeader>
          <CardBody>
            <Box width="100%">
              <Grid templateColumns="1fr 1fr 1fr 1fr" gap={4}>
                <FormControl>
                  <FormLabel>Exam Type</FormLabel>
                  <Select onChange={(e) => handleChange("type", e.target.value)}>
                    <option value="1" selected={formData?.type === 1 ? "selected" : ""}>
                      Mock
                    </option>
                    <option value="2" selected={formData?.type === 2 ? "selected" : ""}>
                      Practice
                    </option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Question Count</FormLabel>
                  <Input
                    type="number"
                    min="1"
                    name="question_count"
                    value={formData.question_count}
                    onChange={(e) => handleChange("question_count", e.target.value)}
                    placeholder="Question Count"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Subject</FormLabel>

                  <Select
                    placeholder="Select subject"
                    onChange={(e) => handleChange("subject_id", e.target.value)}
                  >
                    {subjectList.map((subject) => (
                      <option
                        key={subject.subject_id}
                        value={subject.subject_id}
                        selected={formData?.subject_id === subject.subject_id ? "selected" : ""}
                      >
                        {subject.subject_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Time Limit (In Minutes)</FormLabel>
                  <Input
                    type="number"
                    min="1"
                    name="time_limit"
                    value={formData.time_limit}
                    onChange={(e) => handleChange("time_limit", e.target.value)}
                    placeholder="Time Limit (In Minutes)"
                  />
                </FormControl>
              </Grid>
              <Grid templateColumns="1fr" gap={4} mt="20px">
                <FormControl>
                  <FormLabel>Division</FormLabel>
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={(e) => handleChange("division_id", e)}
                    value={formData?.division_id}
                  >
                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                      {divisionList.map((division) => (
                        <Checkbox
                          key={division.division_id}
                          value={division.division_id.toString()}
                        >
                          {division.division_name}
                        </Checkbox>
                      ))}
                    </Grid>
                  </CheckboxGroup>
                </FormControl>
              </Grid>
              <Grid templateColumns="1fr 1fr 1fr" gap={4} mt="20px">
                <RadioGroup
                  defaultValue="1"
                  onChange={(e) => handleChange("status", parseInt(e))}
                  value={formData?.status.toString()}
                >
                  <Flex direction="row" align="center">
                    <FormLabel>Status</FormLabel>
                    <Radio name="status" value="1" marginLeft={2}>
                      Enabled
                    </Radio>
                    <Radio name="status" value="0" marginLeft={2}>
                      Disabled
                    </Radio>
                  </Flex>
                </RadioGroup>
                <FormControl>
                  <Button w="100%" colorScheme="purple" isLoading={loading} onClick={handleSubmit}>
                    {editForm ? "Update" : "Create"} {pageName}
                  </Button>
                </FormControl>
              </Grid>
              {formError && <Text color="red">{formError}</Text>}
            </Box>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{index + 1}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{getExamType(row?.type)}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.question_count}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.subject_name ?? "All Subjects"}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>
                          {getDivisionNames(row?.division_id, divisionList)}
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.time_limit}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Subject;
