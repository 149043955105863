import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdminInfo, selectAuth } from "../redux/authSlice";
// Chakra imports
import { ChakraProvider, Portal, useDisclosure, Spinner } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
export default function Dashboard(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { adminInfo, userPageIds, isAuthenticated, loading, error } = useSelector(selectAuth);
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const dashboardRoutes = routes.filter(
    (route) =>
      route?.noRender !== true &&
      (adminInfo?.admin_type <= 2 ? true : userPageIds?.includes(route.route)),
  );
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getMainMenu = (dashboardRoutes) => {
    let activeRoute = "My Transfer Teacher";
    for (let i = 0; i < dashboardRoutes.length; i++) {
      if (location.pathname.includes(dashboardRoutes[i].route)) {
        return dashboardRoutes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveRoute = (dashboardRoutes) => {
    let activeRoute = "My Transfer Teacher";
    for (let i = 0; i < dashboardRoutes.length; i++) {
      if (dashboardRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(dashboardRoutes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (dashboardRoutes[i].category) {
        let categoryActiveRoute = getActiveRoute(dashboardRoutes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(dashboardRoutes[i].layout + dashboardRoutes[i].path) !== -1
        ) {
          return dashboardRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (dashboardRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < dashboardRoutes.length; i++) {
      if (dashboardRoutes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(dashboardRoutes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(dashboardRoutes[i].layout + dashboardRoutes[i].path) !== -1
        ) {
          if (dashboardRoutes[i].secondaryNavbar) {
            return dashboardRoutes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (dashboardRoutes) => {
    return dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode

  // Redirect to the dashboard if already authenticated
  if (!isAuthenticated) {
    return <Redirect to="/auth/signin" />;
  }

  useEffect(() => {
    dispatch(fetchAdminInfo());
  }, [dispatch]);

  return (
    <>
      {!adminInfo && loading && !error ? (
        <Spinner size="xl" />
      ) : (
        isAuthenticated && (
          <ChakraProvider theme={theme} resetCss={false}>
            <Sidebar
              routes={dashboardRoutes}
              logoText={"My Transfer Teacher"}
              display="none"
              sidebarVariant={sidebarVariant}
              {...rest}
            />
            <MainPanel
              w={{
                base: "100%",
                xl: "calc(100% - 275px)",
              }}
            >
              <Portal>
                <AdminNavbar
                  onOpen={onOpen}
                  logoText={"My Transfer Teacher"}
                  brandText={getMainMenu(dashboardRoutes)}
                  secondary={getActiveNavbar(dashboardRoutes)}
                  fixed={fixed}
                  adminInfo={adminInfo}
                  {...rest}
                />
              </Portal>
              {getRoute() ? (
                <PanelContent>
                  <PanelContainer>
                    <Switch>
                      {getRoutes(dashboardRoutes)}
                      <Redirect from="/admin" to="/admin/dashboard" />
                    </Switch>
                  </PanelContainer>
                </PanelContent>
              ) : null}
              <Footer />
            </MainPanel>
          </ChakraProvider>
        )
      )}
    </>
  );
}
