import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Link,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
  Badge,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { isEmailValid, isPasswordValid } from "../../Utils";

function UserManagement() {
  const dispatch = useDispatch();
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [adminUsers, setAdminUsers] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["SiNo", "Name", "Email", "User Type", "Status", "Action"];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    status: "1",
  });

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [passwordForm, setPasswordForm] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [userCreatedMessage, setUserCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });

    if (key === "name") setNameError("");
    if (key === "email") setEmailError("");
    if (key === "password") setPasswordError("");
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setNameError("");
      setEmailError("");
      setPasswordError("");

      if (!formData.name) {
        setNameError("Name required");
        return;
      }

      if (!formData.email) {
        setEmailError("Email are required");
        return;
      }

      // Validate the email
      if (!isEmailValid(formData.email)) {
        setEmailError("Please enter a valid email address.");
        return;
      }

      // Validate the password
      if (!isPasswordValid(formData.password)) {
        setPasswordError(
          "Password must be at least 6 characters and include alphabet, numeric, and special characters.",
        );
        return;
      }

      const response = await axios.post(
        `${API_ENDPOINT}createadminuser`,
        { ...formData, admin_type: 3 },
        requestHeader,
      );

      setUserCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setNameError(errors.name || "");
          setEmailError(errors.email || "");
          setPasswordError(errors.password || "");
        }
      } else if (response?.data?.status === true) {
        setUserCreatedMessage(response?.data?.message);
        setFormData({
          name: "",
          email: "",
          status: "1",
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setNameError("");
      setEmailError("");
      setPasswordError("");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      setLoading(true);
      setNameError("");
      setEmailError("");

      if (!formData.name) {
        setNameError("Name required");
        return;
      }

      if (!formData.email) {
        setEmailError("Email are required");
        return;
      }

      // Validate the email
      if (!isEmailValid(formData.email)) {
        setEmailError("Please enter a valid email address.");
        return;
      }

      const response = await axios.put(
        `${API_ENDPOINT}updateadminuser/${editForm?.admin_id}`,
        { ...formData, admin_type: 3 },
        requestHeader,
      );

      setUserCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setNameError(errors.name || "");
          setEmailError(errors.email || "");
        }
      } else if (response?.data?.status === true) {
        setUserCreatedMessage(response?.data?.message);
        setFormData({
          name: "",
          email: "",
          status: "1",
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error Updating user:", error);
      setNameError("");
      setEmailError("");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChangeSubmit = async () => {
    try {
      setLoading(true);
      setPasswordError("");

      // Validate the password
      if (!isPasswordValid(formData.password)) {
        setPasswordError(
          "Password must be at least 6 characters and include alphabet, numeric, and special characters.",
        );
        return;
      }

      if (formData.password !== formData.confirm_password) {
        setPasswordError("Password and Confirm Password not matching.");
        return;
      }

      const response = await axios.put(
        `${API_ENDPOINT}updateadminpassword/${passwordForm?.admin_id}`,
        { password: formData.password },
        requestHeader,
      );

      setUserCreated(response?.data?.status);
      setUserCreatedMessage(response?.data?.message);

      setFormData({
        password: "",
        confirm_password: "",
      });
    } catch (error) {
      console.error("Error updating user password:", error);
      setPasswordError("");
      setFormData({
        password: "",
        confirm_password: "",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getsubadmin`, requestHeader);
      setAdminUsers(response?.data?.subAdmins ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setUserCreatedMessage("");
    setUserCreated("");
  };

  const getAdminType = (admin_type) => {
    let adminType = "";
    switch (admin_type) {
      case 2:
        adminType = "Master Admin";
        break;
      case 3:
        adminType = "Sub Admin";
        break;
    }
    return adminType;
  };

  const getAdminStatus = (status) => {
    let adminStatus = "";
    switch (status) {
      case 0:
        adminStatus = (
          <Badge variant="solid" colorScheme="red">
            Disabled
          </Badge>
        );
        break;
      case 1:
        adminStatus = (
          <Badge variant="solid" colorScheme="green">
            Enabled
          </Badge>
        );
        break;
    }
    return adminStatus;
  };

  const handleAddNewUser = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setPasswordForm(false);
    setFormData({
      name: "",
      email: "",
      status: "1",
    });
    scrollToTop();
  };

  const handleEditForm = (user) => {
    setShowAddForm(false);
    setPasswordForm(false);
    setEditForm(user);
    setFormData({
      name: user.name,
      email: user.email,
      status: user.status,
    });
    scrollToTop();
  };

  const handlePasswordForm = (user) => {
    setShowAddForm(false);
    setEditForm(false);
    setPasswordForm(user);
    setFormData({
      name: "",
      email: "",
      status: "1",
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewUser}
        >
          Add New Admin User
        </Button>
      </Stack>
      {userCreatedMessage && (
        <Alert status={userCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{userCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Add Admin User
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl isInvalid={!!nameError}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    placeholder="Enter name"
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!emailError}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    placeholder="Enter email"
                  />
                  <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!passwordError}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    placeholder="Enter Password"
                  />
                  <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    value={formData.status.toString()}
                    onChange={(value) => handleChange("status", value)}
                  >
                    <Stack direction="row">
                      <Radio value="1">Enabled</Radio>
                      <Radio value="0">Disabled</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <Button
                    mt="20px"
                    colorScheme="purple"
                    isLoading={loading}
                    loadingText="Creating User"
                    onClick={handleSubmit}
                  >
                    Create User
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      )}
      {editForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Edit Admin User
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl isInvalid={!!nameError}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    placeholder="Enter name"
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!emailError}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    placeholder="Enter email"
                  />
                  <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    value={formData.status.toString()}
                    onChange={(value) => handleChange("status", value)}
                  >
                    <Stack direction="row">
                      <Radio value="1">Enabled</Radio>
                      <Radio value="0">Disabled</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <Button
                    mt="20px"
                    colorScheme="blue"
                    isLoading={loading}
                    loadingText="Updating User"
                    onClick={handleUpdateSubmit}
                  >
                    Update User Details
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      )}
      {passwordForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Admin User - Update Password : {passwordForm?.name}
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl isInvalid={!!passwordError}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    placeholder="Enter Password"
                  />
                  <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!passwordError}>
                  <FormLabel>Confirm Password</FormLabel>
                  <Input
                    type="password"
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={(e) => handleChange("confirm_password", e.target.value)}
                    placeholder="Enter Confirm Password"
                  />
                </FormControl>

                <FormControl>
                  <Button
                    mt="30px"
                    colorScheme="orange"
                    isLoading={loading}
                    loadingText="Updating Password"
                    onClick={handlePasswordChangeSubmit}
                  >
                    Update Password
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              Admin User List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {adminUsers.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{index + 1}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{row?.email}</Text>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getAdminType(row?.admin_type)}</Text>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getAdminStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      {row?.admin_type == 3 && (
                        <>
                          <Button
                            colorScheme="blue"
                            mr="8px"
                            mb="8px"
                            onClick={() => handleEditForm(row)}
                          >
                            Edit
                          </Button>
                          <Button colorScheme="green" type="button" mr="8px" mb="8px">
                            <Link href={`#/admin/uac/${row.admin_id}`}>UAC</Link>
                          </Button>
                          <Button
                            colorScheme="orange"
                            mr="8px"
                            mb="8px"
                            onClick={() => handlePasswordForm(row)}
                          >
                            Change Password
                          </Button>
                        </>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default UserManagement;
