import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
// import adminReducer from "./adminSlice";

const rootReducer = {
  // admin: adminReducer,
  auth: authReducer,
  // ...other reducers
};

export const store = configureStore({
  reducer: rootReducer,
});
