import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Link,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
  Badge,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { isEmailValid, isPasswordValid } from "../../Utils";

function UserManagement() {
  const dispatch = useDispatch();
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [adminUsers, setAdminUsers] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["SiNo", "Name", "Contact Information", "User Type", "Status", "Action"];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    status: "1",
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getsch-users`, requestHeader);
      setAdminUsers(response?.data?.users ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const getAdminType = (account_type) => {
    let adminType = "";
    switch (account_type) {
      case 0:
        adminType = (
          <Badge variant="outline" colorScheme="cyan">
            Parent
          </Badge>
        );
        break;
      case 1:
        adminType = (
          <Badge variant="outline" colorScheme="purple">
            School
          </Badge>
        );
        break;
      case 2:
        adminType = (
          <Badge variant="outline" colorScheme="teal">
            Student
          </Badge>
        );
        break;
    }
    return adminType;
  };

  const getAdminStatus = (status) => {
    let adminStatus = "";
    switch (status) {
      case 0:
        adminStatus = (
          <Badge variant="solid" colorScheme="red">
            New User
          </Badge>
        );
        break;
      case 1:
        adminStatus = (
          <Badge variant="solid" colorScheme="orange">
            Email Verified
          </Badge>
        );
        break;
      case 2:
        adminStatus = (
          <Badge variant="solid" colorScheme="green">
            Active
          </Badge>
        );
        break;
    }
    return adminStatus;
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              B2C Users List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th color="" key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {adminUsers.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text fontSize="sm" color={textColor}>
                          {index + 1}
                        </Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex
                        alignItems="center"
                        minWidth="100%"
                        flexWrap="nowrap"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        <Text fontSize="sm" color={textColor}>
                          {row?.name} <br />
                          {row?.account_type === 1 ? `School: ${row?.school_name}` : ""}
                          <br />
                          Login Username <b>{row?.username}</b>
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text fontSize="sm" color={textColor} style={{ whiteSpace: "pre-line" }}>
                        {row?.email}
                        <br />
                        {row?.mobile_number}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm" color={textColor}>
                        {getAdminType(row?.account_type)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm" color={textColor}>
                        {getAdminStatus(row?.status)}
                      </Text>
                    </Td>
                    <Td>
                      {row?.status === 0 && (
                        <Link
                          color="teal.400"
                          href={`https://exam.mytransferteacher.com/#/auth/verify-user?email=${row?.email}&usercode=${row?.verify_url}`}
                          target="_blank"
                        >
                          Verify User
                        </Link>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default UserManagement;
