import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import TruncatedParagraph from "./TruncatedParagraph";
import {
  Flex,
  Box,
  Grid,
  Input,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getStatus } from "../../Utils";

function QuestionPassage() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntity] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["Passage ID", "Passage", "Status", "Action"];
  const pageName = "Passage";
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: uploadImage,
      },
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const uploadImage = (file) => {
    // Your image upload logic here
  };
  const [formData, setFormData] = useState({
    question: "",
    status: 0,
    passages_id: 0,
  });

  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [graphFormError, setGraphFormError] = useState(null);
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleHtmlChange = (html) => {
    setFormData((graphFormData) => ({
      ...graphFormData,
      ["question"]: html,
    }));
  };

  const graphFormCancelHandle = () => {
    setFormData({
      question: "",
      status: 0,
      passages_id: 0,
    });
    scrollToTop();
  };

  const handleSubmit = async () => {
    try {
      setGraphFormError("");
      if (!formData.question) {
        setGraphFormError("Question required");
        return;
      }
      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}updatepassage/${editForm?.passages_id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}createpassage`, formData, requestHeader);
      }
      setEntityCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setGraphFormError(errors.question || "");
        }
      } else if (response?.data?.status === true) {
        setEntityCreatedMessage(response?.data?.message);
        graphFormCancelHandle();
        setShowAddForm(false);
        setEditForm(null);
        fetchData();
      }
    } catch (error) {
      setGraphFormError("");
    } finally {
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getpassagelist`, requestHeader);
      setEntity(response?.data?.passagelist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleAddNewEntity = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    graphFormCancelHandle();
    scrollToTop();
  };

  const handleEditForm = (entity) => {
    setShowAddForm(true);
    setEditForm(entity);
    setFormData({
      question: entity.question,
      status: entity.status,
      passages_id: entity.passages_id,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewEntity}
        >
          Add New {pageName}
        </Button>
      </Stack>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? `Edit` : `Add`} {pageName}
            </Text>
          </CardHeader>
          <CardBody>
            <Box p={4} width="100%">
              <Grid templateColumns="1fr" gap={4} width="100%">
                {/* First Row: Select Box with 100% width */}
                <label>Question</label>
                <ReactQuill
                  className="graphical-question"
                  theme="snow"
                  value={formData?.question}
                  onChange={handleHtmlChange}
                  modules={modules}
                  formats={formats}
                />
                <Grid templateColumns="1fr 1fr 1fr" gap={4}>
                  <RadioGroup
                    defaultValue="1"
                    onChange={(e) => handleChange("status", parseInt(e))}
                    value={formData?.status.toString()}
                  >
                    <Flex direction="row" align="center">
                      <label>Status</label>
                      <Radio name="status" value="1" marginLeft={2}>
                        Enabled
                      </Radio>
                      <Radio name="status" value="0" marginLeft={2}>
                        Disabled
                      </Radio>
                    </Flex>
                  </RadioGroup>

                  <Button
                    type="button"
                    colorScheme="blue"
                    mr="8px"
                    mb="8px"
                    onClick={graphFormCancelHandle}
                  >
                    Cancel
                  </Button>

                  <Button colorScheme="green" mr="8px" mb="8px" onClick={handleSubmit}>
                    {formData?.passages_id > 0 ? "Edit" : "Add"} {pageName}
                  </Button>
                </Grid>
                {graphFormError && <Text color="red">{graphFormError}</Text>}
              </Grid>
            </Box>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.passages_id}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>
                          <TruncatedParagraph html={row?.question} />
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default QuestionPassage;
