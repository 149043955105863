import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Select,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getPricingType } from "../../Utils";

function Pricing() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntities] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["ID", "Pricing Type", "Amount", "Action"];
  const pageName = "Pricing";
  const defaultAmount = "1.00";
  const [formData, setFormData] = useState({
    pricing_type: 0,
    amount: 1,
  });

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [formError, setFormError] = useState("");
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}updatepricing/${editForm?.id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}createpricing`, formData, requestHeader);
      }

      setEntityCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setFormError(errors.subject_name || "");
        }
      } else if (response?.data?.status === true) {
        setEntityCreatedMessage(response?.data?.message);
        setFormData({
          pricing_type: 0,
          amount: 1,
        });
        setShowAddForm(false);
        setEditForm(null);
        fetchData();
      }
    } catch (error) {
      setFormError("");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getpricinglist`, requestHeader);
      setEntities(response?.data?.pricinglist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleAddNewEntity = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setFormData({
      pricing_type: 0,
      amount: 1,
    });
    scrollToTop();
  };

  const handleEditForm = (entity) => {
    setShowAddForm(true);
    setEditForm(entity);
    setFormData({
      pricing_type: entity.pricing_type,
      amount: entity.amount,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewEntity}
        >
          Add New {pageName}
        </Button>
      </Stack>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? `Edit` : `Add`} Pricing
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl>
                  <FormLabel>{pageName} Type</FormLabel>
                  <Select onChange={(e) => handleChange("pricing_type", e.target.value)}>
                    <option value="0" selected={formData?.pricing_type === 0 ? "selected" : ""}>
                      Student
                    </option>
                    <option value="1" selected={formData?.pricing_type === 1 ? "selected" : ""}>
                      School
                    </option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Amount</FormLabel>
                  <Input
                    placeholder="Amount"
                    value={parseInt(formData?.amount)}
                    onChange={(e) => handleChange("amount", e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Button mt="30px" colorScheme="purple" isLoading={loading} onClick={handleSubmit}>
                    {editForm ? "Update" : "Create"} {pageName}
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
            <Text>{formError}</Text>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.id}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{getPricingType(row?.pricing_type)}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{row?.amount}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Pricing;
