import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {
  Flex,
  Input,
  Stack,
  HStack,
  Grid,
  Box,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Link,
  Button,
  Select,
  Radio,
  RadioGroup,
  Textarea,
  Tfoot,
} from "@chakra-ui/react";
import { DownloadIcon, ArrowLeftIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";
import { getStatus, getQuestionLevel, getQuestionType } from "../../Utils";

function Question() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [entities, setEntity] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [formSubDivisionList, setFormSubDivisionList] = useState([]);
  const [masterSubDivision, setMasterSubDivision] = useState([]);
  const [passagelist, setPassagelist] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [graphFormError, setGraphFormError] = useState(null);
  const [formErrorUl, setFormErrorUl] = useState("");
  const [entityCreated, setEntityCreated] = useState(false);
  const [entityCreatedMessage, setEntityCreatedMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [graphQuestionData, setGraphQuestionData] = useState({
    questionId: 0,
    subject: 0,
    division: 0,
    subDivisionId: 0,
    questionType: 1,
    level: 1,
    mark: 0,
    passageId: 0,
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    answerHint: "",
    correctOptionId: 1,
    status: 0,
  });
  const [filters, setFilters] = useState({
    subject: "",
    division: "",
    subDivisionId: "",
    questionType: "",
    level: "",
  });
  const captions = [
    "Question ID",
    "Subject Name",
    "Division Name",
    "Sub Division Name",
    "Question",
    "Level",
    "Status",
    "Action",
  ];
  const pageName = "Question";
  const fileHeaders = [
    "QuestionID",
    "SubjectId",
    "DivisionId",
    "SubDivisionId",
    "Mark",
    "PassageId",
    "Question",
    "Answer 1",
    "Answer 2",
    "Answer 3",
    "Answer 4",
    "Answer 5",
    "Answer Solving/Explanation Method",
    "Answer",
    "Level (1-Easy, 2-Medium, 3-Difficult)",
    "Status (0-Disabled, 1-Enabled)",
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: uploadImage,
      },
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "script",
  ];

  const uploadImage = (file) => {
    // Your image upload logic here
  };

  const handleChange = (html) => {
    setGraphQuestionData((graphFormData) => ({
      ...graphFormData,
      ["question"]: html,
    }));
  };

  const handleAnswerChange = (html) => {
    setGraphQuestionData((graphFormData) => ({
      ...graphFormData,
      ["answerHint"]: html,
    }));
  };

  const handleOptionsChange = (html, key) => {
    setGraphQuestionData((graphFormData) => ({
      ...graphFormData,
      [key]: html,
    }));
  };

  const graphFormHandleChange = (field, value) => {
    if (field === "division") {
      setSubDivisionList(masterSubDivision.filter((item) => item.division_id == value));
    }
    setGraphQuestionData((graphFormData) => ({
      ...graphFormData,
      [field]: value,
    }));
  };

  const filterHandleChange = (field, value) => {
    if (field === "division") {
      if (value === "") {
        setFormSubDivisionList([...masterSubDivision]);
      } else {
        setFormSubDivisionList(masterSubDivision.filter((item) => item.division_id == value));
      }
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const fetchData = async (url = "") => {
    try {
      const response = await axios.get(
        url !== ""
          ? url
          : `${API_ENDPOINT}getquestionlist?subject_id=${filters?.subject}&division_id=${filters?.division}&sub_division_id=${filters?.subDivisionId}&level=${filters?.level}`,
        requestHeader,
      );
      setEntity(response?.data?.questions?.data ?? []);
      setPaginationData(response?.data?.questions?.links);
      const per_page = response?.data?.questions?.per_page ?? 0;
      const current_page = response?.data?.questions?.current_page ?? 0;
      setCurrentPage(current_page * per_page - per_page);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchFilterFormData = async () => {
    try {
      const subjectsResponse = await axios.get(`${API_ENDPOINT}getsubjectlist`, requestHeader);
      setSubjectList(subjectsResponse?.data?.subjectlist ?? []);
    } catch (err) {
      console.error(err);
    }

    try {
      const subjectsResponse = await axios.get(`${API_ENDPOINT}getdivisionlist`, requestHeader);
      setDivisionList(subjectsResponse?.data?.divisionlist ?? []);
    } catch (err) {
      console.error(err);
    }

    try {
      const subjectsResponse = await axios.get(`${API_ENDPOINT}getsubdivisionlist`, requestHeader);
      setMasterSubDivision(subjectsResponse?.data?.subDivisionlist ?? []);
      // setSubDivisionList(subjectsResponse?.data?.subDivisionlist ?? []);
    } catch (err) {
      console.error(err);
    }

    try {
      const passagelist = await axios.get(`${API_ENDPOINT}getpassagelist`, requestHeader);
      setPassagelist(passagelist?.data?.passagelist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setEntityCreatedMessage("");
    setEntityCreated("");
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setError(null);
  };

  const handleSubmit = async (formData) => {
    try {
      const response = await axios.post(`${API_ENDPOINT}addquestions`, formData, requestHeader);
      let callErrorMessage = "";
      if (response?.data?.success?.length > 0) {
        callErrorMessage = "Questions updated successfully.";
        setEntityCreated(true);
      }

      const errorEntries = Object.entries(response?.data?.errors);
      if (errorEntries?.length) {
        callErrorMessage = "Error updating questions";
        let callErrorMessageUl = "<ul>";
        errorEntries.map(
          ([index, message]) =>
            (callErrorMessageUl +=
              "<li>Row No : " + (parseInt(index?.split("#")?.[0]) + 2) + " - " + message + "</li>"),
        );
        callErrorMessageUl += "</ul>";
        setFormErrorUl(callErrorMessageUl);
        setEntityCreated(false);
      }
      setEntityCreatedMessage(callErrorMessage);
      // fetchData();

      setGraphQuestionData({
        questionId: 0,
        subject: 0,
        division: 0,
        subDivisionId: 0,
        questionType: 1,
        level: 1,
        mark: 0,
        passageId: 0,
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        option5: "",
        answerHint: "",
        correctOptionId: 0,
        status: 0,
      });
      scrollToTop();
    } catch (error) {
      setEntityCreated(false);
      if (error.response) {
        setEntityCreatedMessage("Something went wrong : " + error.response?.data?.message);
      } else if (error.request) {
        setEntityCreatedMessage("Something went wrong : " + error.request);
      } else {
        setEntityCreatedMessage("Something went wrong : " + error.message);
      }
    }
  };

  const handleUpload = () => {
    if (!file) {
      setError("Please select a file");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one you want to validate
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Validate headers
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        if (
          !headers ||
          headers.length < 14 ||
          !fileHeaders.every((header) => headers.includes(header))
        ) {
          setError("Invalid headers.");
          return;
        }

        // Validate rows
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
        if (rows.length < 1) {
          setError("At least one rows is required");
          return;
        }

        const questions = rows.slice(1).map((row) => {
          const columns6to11 = row.slice(7, 13);
          const answerColumns = {};
          columns6to11.forEach((value, index) => {
            answerColumns[index + 1] = value;
          });
          return {
            question_id: row[0],
            subject_id: row[1],
            division_id: row[2],
            sub_division_id: row[3],
            mark: row[4],
            passages_id: row[5],
            question: row[6],
            options: JSON.stringify(answerColumns),
            correct_option_id: row[14],
            level: row[15],
            status: row[16],
          };
        });

        handleSubmit({ questions });
      } catch (error) {
        setError("Error reading the Excel file. Please ensure it is a valid Excel file.");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadQuestionExcel = async () => {
    const data = [
      fileHeaders,
      ...entities
        ?.filter((item) => item.subject_id === 1)
        ?.map((q) => {
          const options = q.options;
          const keyValuePairs = Object.entries(JSON.parse(options));
          return [
            q.question_id,
            q.subject_id,
            q.division_id,
            q.sub_division_id,
            q.mark,
            q.passages_id,
            q.question,
            keyValuePairs?.[0]?.[1],
            keyValuePairs?.[1]?.[1],
            keyValuePairs?.[2]?.[1],
            keyValuePairs?.[3]?.[1],
            keyValuePairs?.[4]?.[1],
            keyValuePairs?.[5]?.[1],
            q?.correct_option_id,
            q.level,
            q.status,
          ];
        }),
    ];

    if (data?.length > 1) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      worksheet.addRows(data);

      const blob = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        "MttQuestions.xlsx",
      );
    } else {
      setEntityCreatedMessage("No questions found");
      setEntityCreated(false);
    }
  };

  const downloadEmptyExcel = async () => {
    const data = [fileHeaders];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    worksheet.addRows(data);

    const blob = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      "MttQuestionTemplate.xlsx",
    );
  };

  const graphFormSubmitHandle = (e) => {
    e.preventDefault();
    if (graphQuestionData?.subject === 0) {
      setGraphFormError("Please select a valid Subject");
      return;
    }

    if (graphQuestionData?.division === 0) {
      setGraphFormError("Please select a valid Division");
      return;
    }

    if (graphQuestionData?.subDivisionId === 0) {
      setGraphFormError("Please select a valid Sub Division");
      return;
    }

    if (graphQuestionData?.mark <= 0) {
      setGraphFormError("Please enter a valid Mark");
      return;
    }

    if (graphQuestionData?.level <= 0) {
      setGraphFormError("Please enter a valid Level");
      return;
    }

    if (graphQuestionData?.question === "") {
      setGraphFormError("Please enter a valid Question");
      return;
    }

    if (graphQuestionData?.correctOptionId === 0) {
      setGraphFormError("Please select a valid Correct Answer");
      return;
    }

    let answerColumns = {};
    answerColumns[1] = graphQuestionData?.option1;
    answerColumns[2] = graphQuestionData?.option2;
    answerColumns[3] = graphQuestionData?.option3;
    answerColumns[4] = graphQuestionData?.option4;
    answerColumns[5] = graphQuestionData?.option5;
    answerColumns[6] = graphQuestionData?.answerHint;

    const question = {
      question_id: graphQuestionData?.questionId ?? 0,
      subject_id: parseInt(graphQuestionData?.subject),
      division_id: parseInt(graphQuestionData?.division),
      sub_division_id: parseInt(graphQuestionData?.subDivisionId),
      mark: parseInt(graphQuestionData?.mark),
      passages_id: graphQuestionData?.passageId,
      question: graphQuestionData?.question,
      options: JSON.stringify(answerColumns),
      correct_option_id: graphQuestionData?.correctOptionId,
      level: parseInt(graphQuestionData?.level),
      status: graphQuestionData?.status,
    };

    handleSubmit({ questions: [question] });
  };

  const graphFormCancelHandle = () => {
    setGraphQuestionData({
      questionId: 0,
      subject: 0,
      division: 0,
      subDivisionId: 0,
      questionType: 1,
      level: 1,
      mark: 0,
      passageId: 0,
      question: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option5: "",
      answerHint: "",
      correctOptionId: 0,
      status: 0,
    });
    scrollToTop();
  };

  const handleShowQuestions = () => {
    if (authToken) {
      fetchData();
    }
  };

  const handleEditForm = (question) => {
    const keyValuePairs = Object.entries(JSON.parse(question?.options));
    setGraphQuestionData({
      questionId: question?.question_id,
      subject: question?.subject_id,
      division: question?.division_id,
      subDivisionId: question?.sub_division_id,
      level: question?.level,
      mark: question?.mark,
      passageId: question?.passages_id,
      question: question?.question,
      option1: keyValuePairs?.[0]?.[1],
      option2: keyValuePairs?.[1]?.[1],
      option3: keyValuePairs?.[2]?.[1],
      option4: keyValuePairs?.[3]?.[1],
      option5: keyValuePairs?.[4]?.[1],
      answerHint: keyValuePairs?.[5]?.[1],
      correctOptionId: question?.correct_option_id,
      status: question?.status,
    });

    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const updateUrlParams = (currentUrl) => {
    const url = new URL(currentUrl);
    const searchParams = new URLSearchParams(url.search);
    // searchParams.delete('page');
    searchParams.set("subject_id", filters?.subject);
    searchParams.set("division_id", filters?.division);
    searchParams.set("sub_division_id", filters?.subDivisionId);
    searchParams.set("level", filters?.level);
    // searchParams.set('page', '1');
    url.search = searchParams.toString();
    return url.toString();
  };

  const getQuestionsWithPage = (url) => {
    fetchData(url);
  };

  const wrapPlainTextInHtml = (text) => {
    if (!text) return "";
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(text);
    return isHtml ? text : `<p>${text}</p>`;
  };

  useEffect(() => {
    if (graphQuestionData?.questionId > 0) {
      setSubDivisionList(
        masterSubDivision.filter((item) => item.division_id == graphQuestionData?.division),
      );
      console.log(
        "MYLOG graphQuestionData changed",
        graphQuestionData,
        masterSubDivision.filter((item) => item.division_id == graphQuestionData?.division),
      );
    }
  }, [graphQuestionData]);

  useEffect(() => {
    if (authToken) {
      // fetchData();
      fetchFilterFormData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {entityCreatedMessage && (
        <Alert status={entityCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{entityCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      <Card>
        <CardHeader p="8px 0">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              Normal Question Upload
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex>
            <Box>
              <Stack spacing={4} direction="row">
                <Input type="file" accept=".xls, .xlsx" w="300px" onChange={handleFileChange} />
                <Button
                  w="150px"
                  colorScheme="purple"
                  onClick={handleUpload}
                  leftIcon={<DownloadIcon />}
                >
                  Upload
                </Button>

                <Button
                  w="200px"
                  colorScheme="whatsapp"
                  onClick={downloadEmptyExcel}
                  leftIcon={<DownloadIcon />}
                >
                  Download Template
                </Button>
              </Stack>
            </Box>
          </Flex>
        </CardBody>
        {error && <Text color="red">{error}</Text>}
        <span dangerouslySetInnerHTML={{ __html: formErrorUl }} />
      </Card>

      <Card my="22px">
        <CardHeader p="8px 0">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              Question Management
            </Text>
          </Flex>
        </CardHeader>
        <CardBody pb="30px">
          <Box p={4} width="100%">
            <Grid templateColumns="1fr" gap={4} width="100%">
              <Grid templateColumns="1fr 1fr 1fr 1fr 1fr" gap={4}>
                <Select
                  placeholder="Select subject"
                  onChange={(e) => graphFormHandleChange("subject", e.target.value)}
                >
                  {subjectList.map((subject) => (
                    <option
                      key={subject.subject_id}
                      value={subject.subject_id}
                      selected={graphQuestionData?.subject === subject.subject_id ? "selected" : ""}
                    >
                      {subject.subject_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select division"
                  onChange={(e) => graphFormHandleChange("division", e.target.value)}
                >
                  {divisionList.map((division) => (
                    <option
                      key={division.division_id}
                      value={division.division_id}
                      selected={
                        graphQuestionData?.division === division.division_id ? "selected" : ""
                      }
                    >
                      {division.division_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select sub division"
                  onChange={(e) => graphFormHandleChange("subDivisionId", e.target.value)}
                >
                  {subDivisionList.map((subdivision) => (
                    <option
                      key={subdivision.sub_division_id}
                      value={subdivision.sub_division_id}
                      selected={
                        graphQuestionData?.subDivisionId === subdivision.sub_division_id
                          ? "selected"
                          : ""
                      }
                    >
                      {subdivision.sub_division_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select level"
                  onChange={(e) => graphFormHandleChange("level", e.target.value)}
                >
                  <option value="1" selected={graphQuestionData?.level === 1 ? "selected" : ""}>
                    Easy
                  </option>
                  <option value="2" selected={graphQuestionData?.level === 2 ? "selected" : ""}>
                    Medium
                  </option>
                  <option value="3" selected={graphQuestionData?.level === 2 ? "selected" : ""}>
                    Difficult
                  </option>
                </Select>

                <Input
                  type="number"
                  min="0"
                  placeholder="Mark"
                  onChange={(e) => graphFormHandleChange("mark", e.target.value)}
                  value={graphQuestionData?.mark}
                />
              </Grid>

              {/* First Row: Select Box with 100% width */}
              {graphQuestionData?.subject.toString() === "2" ? (
                <></>
              ) : (
                <>
                  <label>Passage</label>
                  <div className="passage-outer">
                    <RadioGroup
                      colorScheme="green"
                      onChange={(e) => graphFormHandleChange("passageId", parseInt(e))}
                      value={graphQuestionData?.passageId}
                    >
                      <Stack spacing={3}>
                        <Radio key="None" value={0}>
                          None
                        </Radio>
                        {passagelist.map((passage) => (
                          <Radio key={passage?.passages_id} value={passage?.passages_id}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `${passage?.question?.slice(0, 200)}...`,
                              }}
                            />
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </div>
                </>
              )}

              <label>Question</label>
              <ReactQuill
                className="graphical-question"
                theme="snow"
                value={graphQuestionData?.question}
                onChange={handleChange}
                modules={modules}
                formats={formats}
              />

              {/* Second Row: Two Columns with Textboxes */}
              <Grid templateColumns="1fr" gap={4}>
                <label>Option 1</label>
                <ReactQuill
                  className="graphical-option"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.option1)}
                  onChange={(html) => handleOptionsChange(html, "option1")}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <label>Option 2</label>
                <ReactQuill
                  className="graphical-option"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.option2)}
                  onChange={(html) => handleOptionsChange(html, "option2")}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <label>Option 3</label>
                <ReactQuill
                  className="graphical-option"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.option3)}
                  onChange={(html) => handleOptionsChange(html, "option3")}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <label>Option 4</label>
                <ReactQuill
                  className="graphical-option"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.option4)}
                  onChange={(html) => handleOptionsChange(html, "option4")}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <label>Option 5</label>
                <ReactQuill
                  className="graphical-option"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.option5)}
                  onChange={(html) => handleOptionsChange(html, "option5")}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <label>Answer Solving/Explanation Method</label>
                <ReactQuill
                  className="graphical-question"
                  theme="snow"
                  value={wrapPlainTextInHtml(graphQuestionData?.answerHint)}
                  onChange={handleAnswerChange}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid templateColumns="1fr" gap={4}>
                <RadioGroup
                  defaultValue="1"
                  onChange={(e) => graphFormHandleChange("correctOptionId", parseInt(e))}
                  value={graphQuestionData?.correctOptionId.toString()}
                >
                  <Flex direction="row" align="center">
                    <label>Select Correct Answer</label>
                    <Radio name="correct_option_id" value="1" marginLeft={2}>
                      Option 1
                    </Radio>
                    <Radio name="correct_option_id" value="2" marginLeft={2}>
                      Option 2
                    </Radio>
                    <Radio name="correct_option_id" value="3" marginLeft={2}>
                      Option 3
                    </Radio>
                    <Radio name="correct_option_id" value="4" marginLeft={2}>
                      Option 4
                    </Radio>
                    <Radio name="correct_option_id" value="5" marginLeft={2}>
                      Option 5
                    </Radio>
                  </Flex>
                </RadioGroup>
              </Grid>
              <Grid templateColumns="1fr 1fr 1fr" gap={4}>
                <RadioGroup
                  defaultValue="1"
                  onChange={(e) => graphFormHandleChange("status", parseInt(e))}
                  value={graphQuestionData?.status.toString()}
                >
                  <Flex direction="row" align="center">
                    <label>Status</label>
                    <Radio name="status" value="1" marginLeft={2}>
                      Enabled
                    </Radio>
                    <Radio name="status" value="0" marginLeft={2}>
                      Disabled
                    </Radio>
                  </Flex>
                </RadioGroup>

                <Button
                  type="button"
                  colorScheme="blue"
                  mr="8px"
                  mb="8px"
                  onClick={graphFormCancelHandle}
                >
                  Cancel
                </Button>

                <Button colorScheme="green" mr="8px" mb="8px" onClick={graphFormSubmitHandle}>
                  {graphQuestionData?.questionId > 0 ? "Edit" : "Add"} Question
                </Button>
              </Grid>
              {graphFormError && <Text color="red">{graphFormError}</Text>}
            </Grid>
          </Box>
        </CardBody>
      </Card>

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="8px 0">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              {pageName} List
            </Text>

            <Box>
              <Stack spacing={4} direction="row">
                <Select
                  placeholder="Select subject"
                  onChange={(e) => filterHandleChange("subject", e.target.value)}
                >
                  <option value="">All</option>
                  {subjectList.map((subject) => (
                    <option key={subject.subject_id} value={subject.subject_id}>
                      {subject.subject_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select division"
                  onChange={(e) => filterHandleChange("division", e.target.value)}
                >
                  <option value="">All</option>
                  {divisionList.map((division) => (
                    <option key={division.division_id} value={division.division_id}>
                      {division.division_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select sub division"
                  onChange={(e) => filterHandleChange("subDivisionId", e.target.value)}
                >
                  <option value="">All</option>
                  {formSubDivisionList.map((subDivision) => (
                    <option key={subDivision.sub_division_id} value={subDivision.sub_division_id}>
                      {subDivision.sub_division_name}
                    </option>
                  ))}
                </Select>

                <Select
                  placeholder="Select level"
                  onChange={(e) => filterHandleChange("level", e.target.value)}
                >
                  <option value="">All</option>
                  <option value="1">Easy</option>
                  <option value="2">Medium</option>
                  <option value="3">Difficult</option>
                </Select>
              </Stack>
              <Stack spacing={4} mt={3} direction="row">
                <Button colorScheme="blue" onClick={handleShowQuestions} w="200px">
                  Show Questions
                </Button>
                {entities?.length > 0 && (
                  <>
                    <Button
                      colorScheme="whatsapp"
                      onClick={downloadQuestionExcel}
                      leftIcon={<DownloadIcon />}
                      w="200px"
                    >
                      Download Questions
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {entities.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{currentPage + index + 1}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.subject_name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.division_name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.sub_division_name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>
                          <span dangerouslySetInnerHTML={{ __html: row?.question }} />
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getQuestionLevel(row?.level)}</Text>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              {entities?.length > 0 && (
                <Tr>
                  <Td colSpan="7" align="center">
                    <HStack spacing={2} mt={4} justify="center">
                      {paginationData?.map((p) => (
                        <Button
                          variant={p?.active ? "solid" : "outline"}
                          colorScheme="teal"
                          aria-label={p?.label}
                          fontSize="14px"
                          disabled={p?.url === null ? "disabled" : ""}
                          mr="5px"
                          onClick={() =>
                            getQuestionsWithPage(p?.url ? updateUrlParams(p?.url) : "")
                          }
                        >
                          <span dangerouslySetInnerHTML={{ __html: p?.label }} />
                        </Button>
                      ))}
                    </HStack>
                  </Td>
                </Tr>
              )}
            </Tfoot>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Question;
