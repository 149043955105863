import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { MdAdd } from "react-icons/md";
import {
  Flex,
  Input,
  Link,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Button,
  Badge,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { API_ENDPOINT } from "../../constants";

function Pages() {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [pages, setPages] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const captions = ["SiNo", "Page Name", "Route", "Status", "Action"];

  const [formData, setFormData] = useState({
    name: "",
    path: "",
    status: "1",
  });

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [nameError, setNameError] = useState("");
  const [pathError, setPathError] = useState("");
  const [pageCreated, setPageCreated] = useState(false);
  const [pageCreatedMessage, setPageCreatedMessage] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });

    if (key === "name") setNameError("");
    if (key === "path") setPathError("");
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setNameError("");
      setPathError("");

      if (!formData.name) {
        setNameError("Name required");
        return;
      }

      if (!formData.path) {
        setPathError("Path is required");
        return;
      }

      let response;
      if (editForm) {
        response = await axios.put(
          `${API_ENDPOINT}updatepage/${editForm?.page_id}`,
          formData,
          requestHeader,
        );
      } else {
        response = await axios.post(`${API_ENDPOINT}createpage`, formData, requestHeader);
      }

      setPageCreated(response?.data?.status);
      if (response?.data?.errors && response?.data?.status === false) {
        const errors = response?.data?.errors;
        if (errors) {
          setNameError(errors.name || "");
          setPathError(errors.path || "");
        }
      } else if (response?.data?.status === true) {
        setPageCreatedMessage(response?.data?.message);
        setFormData({
          name: "",
          path: "",
          status: "1",
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error creating page:", error);
      setNameError("");
      setPathError("");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}getpagelist`, requestHeader);
      setPages(response?.data?.pagelist ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  const closeSnackbar = () => {
    setPageCreatedMessage("");
    setPageCreated("");
  };

  const getPageStatus = (status) => {
    let adminStatus = "";
    switch (status) {
      case 0:
        adminStatus = (
          <Badge variant="solid" colorScheme="red">
            Disabled
          </Badge>
        );
        break;
      case 1:
        adminStatus = (
          <Badge variant="solid" colorScheme="green">
            Enabled
          </Badge>
        );
        break;
    }
    return adminStatus;
  };

  const handleAddNewPage = () => {
    setShowAddForm(!showAddForm);
    setEditForm(false);
    setFormData({
      name: "",
      path: "",
      status: "1",
    });
    scrollToTop();
  };

  const handleEditForm = (page) => {
    setShowAddForm(true);
    setEditForm(page);
    setFormData({
      name: page.name,
      path: page.path,
      status: page.status,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack direction="row" spacing={4} align="flex-end">
        <Button
          leftIcon={<MdAdd />}
          colorScheme="purple"
          variant="solid"
          onClick={handleAddNewPage}
        >
          Add New Page
        </Button>
      </Stack>
      {pageCreatedMessage && (
        <Alert status={pageCreated ? "success" : "error"}>
          <AlertIcon />
          <AlertTitle>{pageCreatedMessage}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeSnackbar} />
        </Alert>
      )}
      {showAddForm && (
        <Card>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {editForm ? `Edit Page : ${editForm.name}` : "Add Page"}
            </Text>
          </CardHeader>
          <CardBody>
            <Flex>
              <Stack spacing={4} direction="row">
                <FormControl isInvalid={!!nameError}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    placeholder="Enter name"
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!pathError}>
                  <FormLabel>Route</FormLabel>
                  <Input
                    type="text"
                    name="path"
                    value={formData.path}
                    onChange={(e) => handleChange("path", e.target.value)}
                    placeholder="Enter route"
                  />
                  <FormErrorMessage>{pathError}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    value={formData.status.toString()}
                    onChange={(value) => handleChange("status", value)}
                  >
                    <Stack direction="row">
                      <Radio value="1">Enabled</Radio>
                      <Radio value="0">Disabled</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <Button
                    mt="20px"
                    colorScheme="purple"
                    isLoading={loading}
                    loadingText="Creating Page"
                    onClick={handleSubmit}
                  >
                    {editForm?.page_id ? "Update" : "Create"} Page
                  </Button>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      )}

      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              Page List
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="striped" className="users-table" color={textColor}>
            <Thead>
              <Tr>
                {captions.map((caption, idx) => {
                  return (
                    <Th key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {pages.map((row, index) => {
                return (
                  <Tr>
                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{index + 1}</Text>
                      </Flex>
                    </Td>

                    <Td>
                      <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
                        <Text color={textColor}>{row?.name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color={textColor}>{row?.path}</Text>
                    </Td>
                    <Td>
                      <Text color={textColor}>{getPageStatus(row?.status)}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        mr="4px"
                        mb="4px"
                        onClick={() => handleEditForm(row)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Pages;
